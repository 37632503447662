import React, { lazy, Suspense } from 'react';

const LazyStain = lazy(() => import('./Stain'));

const Stain = props => (
  <Suspense fallback={null}>
    <LazyStain {...props} />
  </Suspense>
);

export default Stain;
