import React, { lazy, Suspense,useState } from 'react';

const LazySelectShape = lazy(() => import('./SelectShape'));

const SelectShape = props => {



return(
  <Suspense fallback={null}>
    <LazySelectShape {...props} />
  </Suspense>)
};

export default SelectShape;
