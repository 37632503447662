import React, { lazy, Suspense } from 'react';

const LazyProductStyleComponent = lazy(() => import('./ProductStyleComponent'));

const ProductStyleComponent = props => (
  <Suspense fallback={null}>
    <LazyProductStyleComponent {...props} />
  </Suspense>
);

export default ProductStyleComponent;
