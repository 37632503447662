import React, { lazy, Suspense } from 'react';

const LazyPainted = lazy(() => import('./Painted'));

const Painted = props => (
  <Suspense fallback={null}>
    <LazyPainted {...props} />
  </Suspense>
);

export default Painted;
