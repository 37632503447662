import React from 'react';
import PropTypes from 'prop-types';
import styles from './viewitem.module.css';

const Viewitem = () => (
  <div className={styles.Viewitem}>
    Viewitem Component
  </div>
);

Viewitem.propTypes = {};

Viewitem.defaultProps = {};

export default Viewitem;
