import React, { lazy, Suspense } from 'react';

const LazyAddon = lazy(() => import('./Addon'));

const Addon = props => (
  <Suspense fallback={null}>
    <LazyAddon {...props} />
  </Suspense>
);

export default Addon;
