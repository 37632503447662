import styles from './publiclayout.module.css'
import React from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
const { Header, Content, Footer } = Layout;


const currentYear = new Date().getFullYear();


const Publiclayout = ({ children }) => (
  
  <Layout className="layout">
    <Header id='header'>
        <div className="logo">
          <img src='/logo.jpg' width={120} />
        </div>
    
      </Header>
      <Content
        className={styles.mainContent}
      >
        {/* <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className="site-layout-content">
            {children}
        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        BLOCKSetc &copy;{currentYear} All Rights Reserved
      </Footer>
    </Layout>
);

Publiclayout.propTypes = {};

Publiclayout.defaultProps = {};

export default Publiclayout;
