import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import styles from "./home.module.css";
import { Col, Row, Button, message, Steps, theme, Space, Spin } from "antd";
import SelectSize from "../selectsize/selectsize.lazy";
import SelectShape from "../SelectShape/SelectShape.lazy";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
import Background from "../Background/Background.lazy";
import Addon from "../Addon/Addon.lazy";
import Text from "../Text/Text.lazy";
import Summary from "../Summary/Summary.lazy";
import { fabric } from "fabric";
import Stain from "../Stain/Stain.lazy";
import Painted from "../Painted/Painted.lazy";
import ChangeColors from "../ChangeColors/ChangeColors";
import Fabdesign from "../fabdesign/fabdesign.lazy";
import CubePreview from "../cubePreview/cubePreview";
import EndResult from "../endResult/endResult";
import { useFabricJSEditor } from "fabricjs-react";
import Product from "../ProductComponent/ProductComponent.lazy";
import ProductStyleComponent from "../ProductStyleComponent/ProductStyleComponent.lazy";
import ProductShapeComponent from "../ProductShapeComponent/ProductShapeComponent";
import { Scrollbar } from 'react-scrollbars-custom';


const steps = [
  {
    title: "Size",
  },
  {
    title: "Shape",
  },
  {
    title: "Background",
  },
  {
    title: "Add on",
  },
  {
    title: "Text",
  },
  {
    title: "Summary",
  },
];


const cubicSteps = [
  {
    title: "Style",
  },
  {
    title: "Product",
  },
  {
    title: "Size",
  },
  {
    title: "Paint",
  },
  {
    title: "Text",
  },
  {
    title: "Summary",
  },
];



const Home = () => {

  const [loading, setLoading] = useState(false)


  const [btnDisable, setbtnDisable] = useState(true);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const itemsCubic = cubicSteps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  // setCurrent(5);

  const [text, setText] = useState([]);
  const [cubeimage1, setCubeimage1] = useState(null);
  const [cubeimage2, setCubeimage2] = useState(null);
  const [cubeimage3, setCubeimage3] = useState(null);
  const [cubeimage4, setCubeimage4] = useState(null);
  const [cubeimage5, setCubeimage5] = useState(null);
  const [cubeimage6, setCubeimage6] = useState(null);
  const [buyData, setBuyData] = useState();
  const [showCube, setshowCube] = useState("Scaled");

  // console.log('buyData', buyData)

  const setBtncubicfun = (toggle) => {
    console.log("toggle" + toggle)
    setshowCube(toggle)
  }

  const hideCube = () => {
    setshowCube("")
  }

  const setNavButtonState = (isBack) => {

    if ((current == 0 && selectedProductShape.Name == undefined)
      || (current == 1 && selectedSize.name == undefined)
      || (current == 2 && selectedPaint.Name == undefined)

    ) {
      setbtnDisable(true);
    }

  }

  const goToTop = () => {
    window.scrollTo({ top: document.getElementById('header'), behavior: 'smooth' });
  };

  const next = () => {




    // /console.log('current tab number is ',current)
    if (current >= 3) {
      editor?.deleteAll()
    }
    if (selectedProductType.Name === "Qubic" && (current === 4)) {
      const datalistt = productAddons.sides[sideno].inputs;
      // Fields Validation
      if (datalistt != undefined && datalistt.length > 0) {
        for (let i = 0; i < datalistt.length; i++) {
          console.log('datalistt', datalistt)
          if (datalistt.length > 1 && sideno == 5 && datalistt[0].title == "Custom Message if image is not chosen" && datalistt[0].value.name != ''
            && datalistt[1].value.name != ''
          ) {
            message.error('Please choose one of the following options: the image or the text.');
            return;

          }
          // if(datalistt[i].value.name == "" && datalistt[i].type != "DesignElementImage"){
          //   message.error(datalistt[i].title +' is required');
          //     return;
          //     }
        }
        // setbtnDisable(false);


      }

      editor?.deleteAll()
      if (sideno <= 4) {

        editor?.deleteAll()
        editor?.canvas.setBackgroundColor(selectedPaint.Color, editor?.canvas.renderAll.bind(editor?.canvas));
        setSideNo(sideno + 1);
        setBtnActive(sideno + 1);
      } else {
        setCurrent(current + 1);
      }
    } else {
      setCurrent(current + 1);
      // setbtnDisable(true);

    }


    setNavButtonState()
    /*
        if (selectedProductStyle.Name === 'Block1') {
    
          setbtnDisable(true);
    
        } else {
    
          setbtnDisable(false);
    
        }
        if (Object.keys(selectedProductShape).length === 0) {
    
          setbtnDisable(true);
        }
        else {
    
          setbtnDisable(false);
    
        }
        if (selectedSize.name === 'Natural1') { 
    
          setbtnDisable(true);
    
        }
        else {
    
          setbtnDisable(false);
    
        }
        if (selectedPaint.Name === 'No Paint1') { 
    
          setbtnDisable(true);
    
        } else { 
    
          setbtnDisable(false);
    
        }*/

    hideCube();

    goToTop();

  };

  const RenderTextureOnCube = () => {

    if (sideno == 0) {
      // console.log('from TextComponent:', sideno);
      setCubeimage1(editor?.canvas.toDataURL({
        format: 'jpeg',
        width: 500,
        height: 500
      }))
    }
    if (sideno == 1) {
      // console.log('from TextComponent:', sideno);
      setCubeimage2(editor?.canvas.toDataURL({
        format: 'jpeg',
        width: 500,
        height: 500
      }))
    }
    if (sideno == 2) {
      // console.log('from TextComponent:', sideno);
      setCubeimage3(editor?.canvas.toDataURL({
        format: 'jpeg',
        width: 500,
        height: 500
      }))
    }
    if (sideno == 3) {
      // console.log('from TextComponent:', sideno);
      setCubeimage4(editor?.canvas.toDataURL({
        format: 'jpeg',
        width: 500,
        height: 500
      }))
    }
    if (sideno == 4) {
      // console.log('from TextComponent:', sideno);
      setCubeimage5(editor?.canvas.toDataURL({
        format: 'jpeg',
        width: 500,
        height: 500
      }))
    }
    if (sideno == 5) {
      // console.log('from TextComponent:', sideno);
      setCubeimage6(editor?.canvas.toDataURL({
        format: 'jpeg',
        width: 500,
        height: 500
      }))
    }

  }
  const prev = () => {

    goToTop();

    hideCube();

    editor?.deleteAll()
    if (selectedProductType.Name === "Qubic" && current === 4) {
      if (sideno >= 1) {

        editor?.canvas.setBackgroundColor(selectedPaint.Color, editor?.canvas.renderAll.bind(editor?.canvas));
        setSideNo(sideno - 1);
        setBtnActive(sideno - 1);
      } else {
        setCurrent(current - 1);
      }
    } else {
      setCurrent(current - 1);
    }

    setbtnDisable(false);

  };



  const [textPrice, setTextPrice] = useState(0);

  const onChange = (val, type) => {
    // console.log("on change working", val, type, sideno);
    if (val.Name !== "") {
      setText({ ...text, [type]: val });
    }
  };



  //   sides: [{
  //     side: "",
  //     inputs: [
  //         {
  //           label:"Name",
  //           Value:"Travis"
  //         }
  //     ]
  // }]



  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const [buyNowComplete, setbuyNowComplete] = useState(true);

  const [selectedSize, setselectedSize] = useState({});
  const [selectedShape, setselectedShape] = useState({});

  const [selectedProductShape, setselectedProductShape] = useState({});

  const [selectedBackground, setselectedBackground] = useState({
    Image: "",
    Name: "",
  });
  const [selectedAddon, setselectedAddon] = useState({
    Image: "",
    Name: "",
    Price: 0,
  });

  const [selectedSummary, setselectedSummary] = useState();

  const [validateEmailDisable, setValidateEmailDisable] = useState(true)

  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const [selectedStain, setSelectedStain] = useState({
    Image: "",
    Name: "",
  });

  const [selectedPaint, setSelectedPaint] = useState({});

  const [selectedColor, setSelectedColor] = useState({
    Color: "",
    Name: "",
  });

  const [shapes, setshapes] = useState([]);
  const [productAddons, setproductAddons] = useState([]);

  const [totalPrice, setTotalPrice] = useState();



  const ProductTypes = [
    {
      Image: "/products/flat.png",
      Name: "Flat",
    },
    {
      Image: "/products/qubic.png",
      Name: "Qubic",
    }
  ];

  const ProductStyles = [
    {
      Image: "/products/flat.png",
      Name: "Ornament",
    },
    {
      Image: "/products/qubic.png",
      Name: "Block",
    }
  ];

  const ShapesList = [
    {
      Image: "/shapes/circle.png",
      Name: "Circle",
      Price: 0,
    },
    {
      Image: "/shapes/square.png",
      Name: "Square",
      Price: 0,
    },
    {
      Image: "/shapes/elephant.png",
      Name: "Elephant",
      Price: 0,
    },
  ];

  const [selectedProductType, setselectedProductType] = useState({
    Image: "/products/qubic.png",
    Name: "Qubic",
  });

  const ProductTypeSelection = (Product) => {
    if (Product) {
      setselectedProductType(Product);
      // console.log("Selected Product is", selectedProductType);
    }
  };

  const [selectedProductStyle, setselectedProductStyle] = useState(
    {
      Image: "/products/qubic.png",
      Name: "Block1",
    }
  );

  const ProductStyleSelection = (Product) => {
    if (Product) {
      setselectedProductStyle(Product);
      setbtnDisable(false);
      setshowCube("Scaled");
      // console.log("Selected Product style is", selectedProductStyle);
    }
  };

  const Size = (size) => {
    if (size) {
      setselectedSize(size);
      setbtnDisable(false);
      // console.log("selected size is", selectedSize);
    }
  };

  const Shape = (shape) => {
    if (shape) {
      setselectedShape(shape);
      // console.log("selected shape is", selectedShape);
    }
  };

  const ProductShape = (ProductShape) => {
    if (ProductShape) {
      setselectedProductShape(ProductShape);
      setbtnDisable(false);
      // console.log("selected shape is", selectedShape);
    }
  };



  const [selectedProductShapes, setselectedProductShapes] = useState();

  const BackgroundSelection = (BG) => {
    if (BG) {
      setselectedBackground(BG);
      // console.log("Selected Background is", selectedBackground);
    }
  };

  const StainSelection = (stain) => {
    if (stain) {
      setSelectedStain(stain);
      // console.log("Selected Stain is", selectedStain);
    }
  };

  const paintSelection = (paint) => {
    if (paint) {
      setSelectedPaint(paint);
      // console.log("Selected Paint is", selectedPaint);
      setbtnDisable(false);
      // setshowCube("Scaled");
    }
  };

  const SelectionAddon = (addon) => {
    if (addon) {
      setselectedAddon(addon);
      // console.log("Selected addon is", selectedAddon);
    }
  };

  const SelectionColor = (Color) => {
    // console.log(Color);
    if (Color.Image) {
      // console.log(Color);
      setSelectedColor({ Color: "" });
      // console.log("Selected Change is", selectedColor);
    } else {
      // console.log(Color);
      setSelectedColor(Color);
      // console.log("Selected Change is", selectedColor);
    }
  };

  const [summary, setSummary] = useState({
    Turnaround: { name: "Modern", duration: "2 weeks", price: 0 },
    email: ""
  });

  const getSummeryData = (data) => {
    setSummary(data);
  };

  const [btncubic, setBtncubic] = useState(true);

  useEffect(() => {
    if (current === 3) {
      let canvImgUrl = editor?.canvas.toDataURL({
        format: 'jpeg',
        width: 500,
        height: 500
      })

      setCubeimage1(canvImgUrl)
      setCubeimage2(canvImgUrl)
      setCubeimage3(canvImgUrl)
      setCubeimage4(canvImgUrl)
      setCubeimage5(canvImgUrl)
      setCubeimage6(canvImgUrl)
    }

    RenderTextureOnCube()
  }, [selectedPaint])

  // useEffect(()=> {

  //   var price = 0;
  //   Object.keys(text).map((key, i) => {
  //     if(text[key].Name !== ""){
  //       console.log(i, text[key].Price)
  //       price += text[key].Price 
  //       console.log(price)
  //     }
  //   // if(text[key].Price)
  //   setTextPrice(price)
  // })

  // console.log(JSON.stringify(text))

  // },[text])



  const calculatePrice = () => {
    let total =
      (selectedSize.price === undefined
        ? (selectedSize.price = 0)
        : selectedSize.price) +
      (selectedShape.Price === undefined
        ? (selectedShape.Price = 0)
        : selectedShape.Price) +
      (selectedPaint.Price === undefined
        ? (selectedPaint.Price = 0)
        : selectedPaint.Price) +
      (selectedAddon.Price === undefined
        ? (selectedAddon.Price = 0)
        : selectedAddon.Price) +
      textPrice +
      summary.Turnaround?.price;
    // console.log(
    //   "selectedSize ",
    //   selectedSize.price + "selectedShape ",
    //   selectedShape.Price + "selectedPaint ",
    //   selectedPaint.Price,
    //   "selectedAddon ",
    //   selectedAddon.Price
    // );

    // console.log('text.Price' + text.Price)

    setTotalPrice(total);

    setselectedSummary({
      textPrice,
      selectedSize,
      selectedShape,
      selectedProductShape,
      selectedPaint,
      selectedStain,
      selectedAddon,
      selectedBackground,
      text,
      summary,
      selectedProductType,
      selectedProductStyle,
      productAddons
    });

    console.log("selected summary", selectedSummary);

    console.log("validate email ", selectedSummary)

  };

  // const [dataAPI, setDataAPI] = useState();
  useEffect(() => {
    setLoading(true);
    // get All Shapes
    fetch(process.env.REACT_APP_API + "/product/productList")
      .then((res) => res.json())
      .then(
        (result) => {
          const shapesObj = [];
          for (var i = 0; i < result.length; i++) {
            const ImageName = result[i].image.replace("/", "-");
            // console.log(ImageName);
            shapesObj.push({
              // Image: result[i].image,
              id: result[i]._id,
              Image: `/cubes/${ImageName}.png`, //static image
              Name: result[i].title,
              Price: 0,
            });
            setselectedProductShapes(shapesObj);
            // ProductShape(shapesObj[0])
          }
          setLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // console.log(error);
        }
      );

    // editor?.canvas.setBackgroundColor('#FFF', editor?.canvas.renderAll.bind(editor?.canvas));
    // editor?.canvas.setBackgroundColor(
    //   "#FFF",
    //   editor?.canvas.renderAll.bind(editor?.canvas)
    // );
  }, []);

  const onSideFieldChange = (newValue, index) => {
    const addonData = productAddons;
    addonData.sides[sideno].inputs[index].value = newValue;

    { setproductAddons(addonData); }

    let isValidated = productAddons.sides[sideno].inputs.some(ele => {
      // console.log("checking element for validation", ele)
      if (ele.required === true || ele.required === "true") {
        return (ele.value.name === null || ele.value.name === "")
      } else {
        return false
      }

    });
    setbtnDisable(isValidated);

    // productAddons.sides[sideno].inputs.map((item, index)=>{
    //   // let isNullOrEmpty = item.some(ele=>(ele.deptName===null || ele.deptName===""))
    //   console.log(item.value.name === "");
    //   (item.value.name === "" ) ? setbtnDisable(true) : setbtnDisable(false)
    // })

  };
  useEffect(() => {
    calculatePrice();

    if (summary.email == undefined || summary.email == '') {
      setValidateEmailDisable(true)
    } else {
      setValidateEmailDisable(false)
    }

    // console.log('productAddons',productAddons)
  }, [
    textPrice,
    selectedSize,
    selectedShape,
    selectedProductShape,
    selectedPaint,
    selectedStain,
    selectedAddon,
    selectedBackground,
    text,
    summary,
    selectedProductType,
    selectedProductStyle,

  ]);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_API + `/product/get/${selectedProductShape.id}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setproductAddons(result);
          setLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // console.log(error);
        }
      );

  }, [selectedProductShape]);

  // //console.log("All text===========", text);

  const [textScreen, setTextScreen] = useState(
    {
      Id: 0,
      Title: "First word",
      Name: "",
      Color: "",
      fontSize: 16,
      fontFamily: "",
      Price: 45,
    },
    {
      Id: 1,
      Title: "Second word",
      Name: "",
      Color: "",
      fontSize: 16,
      fontFamily: "",
      Price: 45,
    },
    {
      Id: 2,
      Title: "Third Word",
      Name: "",
      Color: "",
      fontSize: 16,
      fontFamily: "",
      Price: 45,
    }
  );

  const updateText = (text) => {
    // console.log("textScreen", textScreen);
    setTextScreen(text);
  };

  const [sideno, setSideNo] = useState(0);
  const [btnActive, setBtnActive] = useState(0);

  const [prevSide, setPrevSide] = useState(0);

  const changeSideNo = (num) => {
    // console.log('setSideNo ', num);
    setPrevSide(sideno);
    setSideNo(num - 1);
  };

  // Example POST method implementation:
  async function postData(url, data) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }


  const onClickBuyNow = () => {
    setLoading(true);
    //console.log(prepareData());
    const data = prepareData();
    postData(process.env.REACT_APP_API + "/checkout/buyProduct", data).then((data) => {
      // console.log(data);
      setBuyData(data);
      setbuyNowComplete(false);
      setLoading(false);
    });

  };

  const prepareData = () => {
    const finalSides = productAddons.sides.map(side => {
      const finalInputs = side.inputs.map(input => {
        if (input.type != "DesignElementText" && input.type != "DesignElementImage") {

          if (input.type === "imagegallery" || input.type === "imagegallerysmall") {
            return {
              label: input.title,
              Value: process.env.REACT_APP_WEB + input.value.name
            }
          } else {
            return {
              label: input.title,
              Value: input.value.name
            }
          }
        }

      });

      return {
        side: side.side,
        inputs: finalInputs
      }
    });
    const data = {
      title: productAddons.title,
      price: totalPrice,
      type: selectedProductType.Name,
      style: selectedProductStyle.Name,
      productTypeId: "1",
      size: {
        name: selectedSize.name,
        price: selectedSize.price,
        size: selectedSize.size
      },
      Color: {
        name: selectedPaint.Name,
        code: selectedPaint.Color,
        price: selectedPaint.Price
      },
      sides: finalSides,
      images: [
        cubeimage5,
        cubeimage1,
        cubeimage2,
        cubeimage3,
        cubeimage4,
        cubeimage6,
      ]
    }

    // console.log("very important data", JSON.stringify(data))

    return data;
  }

  const onClickRedirect = () => {
    window.location.href = `https://blocksetcshop.com/product/custom-block/?Price=${totalPrice}&DataUrl=${process.env.REACT_APP_API}/checkout/getProductInfo/${buyData.product.identifier}&ImageBaseUrl=${process.env.REACT_APP_API}/purchasedProducts/${buyData.product.identifier}`;
  }

  const [Visibility, setVisibility] = useState("");

  useEffect(() => {
    if (!buyNowComplete) {
      setVisibility("visibilitynone");
    } else {
      setVisibility("");
    }
  }, [buyNowComplete]);



  // console.log('dataAPI ', dataAPI)

  return (
    <div className={styles.Home}>
      {/* <code>{selectedSummary && JSON.stringify(selectedSummary)}</code> */}
      <Space
        direction="horizontal"
        className={styles.appcontainer}
        style={{ width: "100%" }}
      >
        <Row>
          {Visibility && (
            <Col span={6}>
              &nbsp;
              <br />
              &nbsp;
              <br />
              &nbsp;
              <br />
            </Col>
          )}
          {/* className={styles.bg} */}
          <Col xs={24} sm={24} md={24} lg={12} xl={14} className={`${Visibility}`}>
            <Spin spinning={loading} delay={0} size="large">
              <div className={styles.newbox}>
                <div className={styles.ElementBoxes}>
                  {selectedProductType.Name === "Flat" ? (
                    <Scrollbar style={{width:'100%', height:'100px'}}>
                      <div class="ScrollbarsCustom native trackYVisible trackXVisible">
                        <div class="ScrollbarsCustom-Content">
                          <Steps className={styles.stepssettings} current={current} items={items} labelPlacement="vertical" size="small" />
                        </div>
                      </div>
                    </Scrollbar>
                  ) : (
                      <Scrollbar style={{ width: '100%', height: '100px' }}>
                      <div class="ScrollbarsCustom native trackYVisible trackXVisible">
                        <div class="ScrollbarsCustom-Content">
                          <Steps className={styles.stepssettings} current={current} items={itemsCubic} labelPlacement="vertical" size="small" />
                        </div>
                      </div>
                    </Scrollbar>
                  )}

                  <div className={styles.Elements}>
                    {current === 0 && (
                      <ProductStyleComponent
                        products={ProductStyles}
                        setselectedProductStyle={ProductStyleSelection}
                        defaultValue={selectedProductStyle}
                        className={styles.stepStyle}
                      />
                    )}

                    {current === 1 &&
                      (selectedProductType &&
                        selectedProductType?.Name === "Flat" ? (
                        <SelectSize
                          selectedSize={Size}
                          defaultValue={selectedSize}
                          className={styles.stepStyle}
                        />
                      ) : (
                        <ProductShapeComponent
                          // selectedShape={ProductShape}
                          selectedShape={ProductShape}
                          defaultValue={selectedProductShape}
                          selectedSize={Size}
                          shapesList={selectedProductShapes}
                          className={styles.stepStyle}
                        />
                      ))}
                    {current === 2 &&
                      (selectedProductType &&
                        selectedProductType?.Name === "Flat" ? (
                        <SelectShape
                          selectedShape={Shape}
                          defaultValue={selectedShape}
                          selectedSize={Size}
                          shapesList={ShapesList}
                          className={styles.stepStyle}
                        />
                      ) : (
                        <SelectSize
                          selectedSize={Size}
                          defaultValue={selectedSize}
                          className={styles.stepStyle}
                        />
                      ))}

                    {current === 3 &&
                      (selectedProductType.Name === "Flat" ? (
                        <>
                          {selectedBackground.Name === "" ? (
                            <Background
                              selectedBackground={BackgroundSelection}
                              defaultValue={selectedBackground}
                              className={styles.stepStyle}
                            />
                          ) : (
                            ""
                          )}

                          {selectedBackground.Name &&
                            selectedBackground.Name === "Stains" ? (
                            <Stain
                              selectedStain={StainSelection}
                              selectedBackground={BackgroundSelection}
                              defaultValue={selectedStain}
                              className={styles.stepStyle}
                            />
                          ) : (
                            ""
                          )}

                          {selectedBackground.Name &&
                            selectedBackground.Name === "Painted" ? (
                            <Painted
                              selectedPainted={paintSelection}
                              selectedBackground={BackgroundSelection}
                              defaultValue={selectedPaint}
                              className={styles.stepStyle}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          {
                            <Painted
                              selectedPainted={paintSelection}
                              selectedBackground={BackgroundSelection}
                              defaultValue={selectedPaint}
                              className={styles.stepStyle}
                            />
                          }
                        </>
                      ))}

                    {current === 4 &&
                      (selectedProductType.Name === "Flat" ? (
                        <>
                          {selectedAddon.Name === "" ? (
                            <Addon
                              selectedAddon={SelectionAddon}
                              defaultValue={selectedAddon}
                              className={styles.stepStyle}
                              editor={editor}
                            />
                          ) : (
                            ""
                          )}
                          {selectedAddon.Name !== "" ? (
                            <ChangeColors
                              selectedColor={SelectionColor}
                              defaultValue={selectedColor}
                              selectedAddon={SelectionAddon}
                              className={styles.stepStyle}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <Text
                          setbtnDisable={setbtnDisable}
                          // fontfamiles={fontfamiles}
                          className={styles.stepStyle}
                          onChange={onChange}
                          text={text}
                          fieldsList={
                            productAddons != undefined
                              ? productAddons.sides[sideno]
                              : null
                          }
                          editor={editor}
                          sideno={sideno}
                          changeSideNo={changeSideNo}
                          onSideFieldChange={onSideFieldChange}
                          selectedProductType={selectedProductType}
                          btnActive={btnActive}
                          setBtnActive={setBtnActive}
                          productAddons={productAddons}
                          setCubeimage1={setCubeimage1}
                          setCubeimage2={setCubeimage2}
                          setCubeimage3={setCubeimage3}
                          setCubeimage4={setCubeimage4}
                          setCubeimage5={setCubeimage5}
                          setCubeimage6={setCubeimage6}
                          cubeimage1={cubeimage1}
                          cubeimage2={cubeimage2}
                          cubeimage3={cubeimage3}
                          cubeimage4={cubeimage4}
                          cubeimage5={cubeimage5}
                          cubeimage6={cubeimage6}
                        />
                      ))}
                    {current === 5 && (
                      <>
                        {
                          // <Text
                          //   fontfamiles={fontfamiles}
                          //   className={styles.stepStyle}
                          //   onChange={onChange}
                          //   text={text}
                          //   fieldsList={
                          //     productAddons != undefined
                          //       ? productAddons.sides[sideno]
                          //       : null
                          //   }
                          //   editor={editor}
                          //   sideno={sideno}
                          //   changeSideNo={changeSideNo}
                          //   onSideFieldChange={onSideFieldChange}
                          // />

                          <Summary
                            selectedSummary={selectedSummary}
                            getSummeryData={getSummeryData}
                            className={styles.stepStyle}
                          />
                        }
                      </>
                    )}
                    {current === 6 && (
                      <Summary
                        selectedSummary={selectedSummary}
                        getSummeryData={getSummeryData}
                        className={styles.stepStyle}
                      />
                    )}
                  </div>
                  <div className={styles.actionBTNs}>
                    {current >= 1 && (
                      <Button
                        style={{
                          margin: "0 8px",
                        }}
                        onClick={() => prev()}
                      >
                        <CaretLeftOutlined /> Previous
                      </Button>
                    )}

                    {selectedProductType.Name === "Flat" ? (
                      <>
                        {current < steps.length - 1 && (
                          <Button type="primary" onClick={() => next()} disabled>
                            Next <CaretRightOutlined />
                          </Button>
                        )}

                        {current === steps.length - 1 && (
                          <Button type="primary" onClick={onClickBuyNow}>
                            Finish
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        {current < cubicSteps.length - 1 && (
                          current === 4 ?
                            btnDisable === true ?
                              // Steps
                              // Text side buttons
                              <Button type="primary" onClick={() => next()} disabled>
                                Next <CaretRightOutlined />
                              </Button>
                              :
                              // Steps
                              <Button type="primary" onClick={() => { next(); }}>
                                Next <CaretRightOutlined />
                              </Button>
                            :
                            // Screen Buttons
                            btnDisable === true ?
                              <Button type="primary" onClick={() => { next(); }} disabled>
                                Next <CaretRightOutlined />
                              </Button>
                              // screens
                              : <Button type="primary" onClick={() => { next(); }}>
                                Next <CaretRightOutlined />
                              </Button>
                        )}

                        {current === cubicSteps.length - 1 ?
                          <Button disabled={validateEmailDisable} type="primary" onClick={onClickBuyNow}>
                            Finish
                          </Button> : ''
                        }
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Spin>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <div className={styles.shapBox}>
              {selectedProductShape.Name !== undefined && current >= 1 ? <h3 className={styles.boxTitle + `${selectedProductShape.Name !== undefined && current >= 1 ? " mt--60" : ''}`}>
                {selectedShape.Name !== undefined && current >= 0
                  ? selectedShape.Name + " Shape "
                  : ""}

                {selectedProductShape.Name !== undefined && current >= 1
                  ? selectedProductShape.Name + " "
                  : ""}
                {selectedSize.size !== undefined && current >= 2 ? <br /> : ""}
                {selectedSize.size !== undefined && current >= 2
                  ? "Product Size " + selectedSize.size + '"'
                  : ""}
              </h3> : ''}

              {/* <CubePreview/> */}
              <Fabdesign
                selectedProductType={selectedProductType}
                selectedShape={selectedShape}
                selectedPaint={selectedPaint}
                selectedBackground={selectedBackground}
                selectedStain={selectedStain}
                selectedAddon={selectedAddon}
                selectedSummary={selectedSummary}
                editor={editor}
                onReady={onReady}
                selectedObjects={selectedObjects}
                changeSideNo={changeSideNo}
                sideno={sideno + 1}
                prevSide={prevSide + 1}
                Visibility={Visibility}
                setCubeimage1={setCubeimage1}
                setCubeimage2={setCubeimage2}
                setCubeimage3={setCubeimage3}
                setCubeimage4={setCubeimage4}
                setCubeimage5={setCubeimage5}
                setCubeimage6={setCubeimage6}
                cubeimage1={cubeimage1}
                cubeimage2={cubeimage2}
                cubeimage3={cubeimage3}
                cubeimage4={cubeimage4}
                cubeimage5={cubeimage5}
                cubeimage6={cubeimage6}
                btncubic={setBtncubicfun}
                showCube={showCube}
                current={current}
              />

              <div className={styles.actionBTNs}>
                {/* {Visibility && (
                  <Button style={{ margin: "0 8px" }} onClick={onClickBuyNow}>
                    {" "}
                    Back{" "}
                  </Button>
                )} */}

                {/* <Button
                  style={{
                    margin: "0 8px",
                  }}
                >
                  Share
                </Button> */}

                {Visibility &&
                  <>
                    <Button
                      style={{
                        margin: "0 8px",
                      }}
                      onClick={() => { setCurrent(5); setVisibility(""); }}
                    >
                      <CaretLeftOutlined /> Back
                    </Button>

                    <Button
                      type="primary"
                      style={{
                        margin: "0 8px",
                      }}
                      onClick={onClickRedirect}
                    >
                      Buy Now
                    </Button>
                  </>
                }


                {current >= 2 && totalPrice > 0 ?
                  <span className={styles.pricebtn + ` ant-btn ant-btn-primary`}
                    style={{
                      margin: "0 8px",
                    }}
                  >
                    {totalPrice && "$" + totalPrice}
                  </span> : ''}

              </div>
            </div>
          </Col>
        </Row>
      </Space>
    </div >
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;