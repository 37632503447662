import React, { useRef, Suspense } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader.js'
import { useTexture } from "@react-three/drei"
import { OrbitControls } from '@react-three/drei'
function Box({img1,img2,img3,img4,img5,img6}) {

  const textures = useTexture([
    (img3==undefined||img3 == null?'/side2.jpg':img3),
    (img5==undefined||img5 == null?'/side4.jpg':img5),
    (img1==undefined||img1 == null?'/top.jpg':img1),
    (img6==undefined||img6 == null?'/bottom.jpg':img6),
    (img2==undefined||img2 == null?'/side1.jpg':img2),
    (img4==undefined||img4 == null?'/side3.jpg':img4),
  ]);
  const mesh = useRef();


  return (<mesh ref={mesh}>
    <boxBufferGeometry attach="geometry" args={[2, 2, 2]} />
    {textures.map((texture, idx) =>
      <meshBasicMaterial key={texture.id} attach={`material-${idx}`} map={texture} />
    )}
  </mesh>);
/*
  const texture_1 = useLoader(TextureLoader, '/pug.jpg')
  const texture_2 = useLoader(TextureLoader, '/pug.jpg')
  const texture_3 = useLoader(TextureLoader, '/printio.png')
  const texture_4 = useLoader(TextureLoader, '/printio.png')
  const texture_5 = useLoader(TextureLoader, '/pug.jpg')
  const texture_6 = useLoader(TextureLoader, '/pug.jpg')
  const mesh = useRef()
  useFrame(() => {
    mesh.current.rotation.x = mesh.current.rotation.y += 0.01
  })
  return (
    <mesh ref={mesh} scale={false ? [0.7, 0.7, 0.7] : [0.4, 0.4, 0.4]}>
      <boxBufferGeometry attach="geometry" />
      <meshBasicMaterial attachArray="material" color="red" />
        <meshBasicMaterial attachArray="material" color="green" />
        <meshBasicMaterial attachArray="material" color="blue" />
        <meshBasicMaterial attachArray="material" color="cyan" />
        <meshBasicMaterial attachArray="material" color="magenta" />
        <meshBasicMaterial attachArray="material" color="yellow" />
    </mesh>
  )*/
}

export default function CubePreview(props) {

  return (
    <Canvas>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <Suspense fallback={null}>
        <Box {...props} />
        <OrbitControls />
      </Suspense>
    </Canvas>
  )
}
