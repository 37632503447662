// import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Col, Image, Row, Typography } from "antd";
import styles from "./ProductShapeComponent.module.css";
import { Scrollbar } from 'react-scrollbars-custom';

const { Title } = Typography;

const ProductShapeComponent = ({ selectedShape, defaultValue, selectedSize, shapesList }) => {


  return (
    <div className={styles.Container}>
      <div className={styles.ContainerAlignment}>
        <Row className={[styles.centerAlign, styles.fullWidth]}>
          <Col span={24}>
            <Title level={4}>Choose the Product</Title>
          </Col>
        </Row>
        <Scrollbar style={{ width: '100%', height: '274px' }}>
          <div class="ScrollbarsCustom native trackYVisible trackXVisible">
            <div class="ScrollbarsCustom-Content">
              <Row
                className={[
                  styles.centerAlign,
                  styles.fullWidth,
                ]}
                gutter={6}
              >
                {shapesList.map((shape, index) => (
                  <div
                    key={index}
                    onClick={() => selectedShape(shape)}
                    className={`${styles.itemdiv} ${shape.Name === defaultValue.Name ? styles.active : ""} `}
                  >
                    <div className={styles.shapeBox}>
                      <Image
                        className={styles.Image}
                        src={shape.Image}
                        preview={false}
                      />
                      <Title className={styles.Title} level={5}>
                        {shape.Name}
                      </Title>
                    </div>

                    {shape.Price !== 0 ? (
                      <span className={styles.badge}>${shape.Price}</span>
                    ) : (
                      ""
                    )}
                  </div>
                ))}

              </Row>
            </div>
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

// ProductShapeComponent.propTypes = {};

// ProductShapeComponent.defaultProps = {};

export default ProductShapeComponent;
