import React from "react";
import PropTypes from "prop-types";
import styles from "./ChangeColors.module.css";
import Title from "antd/es/typography/Title";
import { Button, Col, Image, Row } from "antd";
import { RotateLeftOutlined } from "@ant-design/icons";

const ChangeColors = ({ selectedColor, defaultValue, selectedAddon }) => {
  const colors = [
    {
      Image: "/addons/clearDesign.png",
    },
    {
      Color: "rgb(151, 52, 51)",
      Name: "Deep Red",
    },
    {
      Color: "rgb(229, 161, 175)",
      Name: "Bright Pink",
    },
    {
      Color: "rgb(212, 165, 164)",
      Name: "Coral",
    },
    {
      Color: "rgb(224, 197, 190)",
      Name: "Blush",
    },
    {
      Color: "rgb(239, 193, 189)",
      Name: "Peach",
    },
    {
      Color: "rgb(98, 80, 121)",
      Name: "Purple",
    },
    {
      Color: "rgb(152, 124, 145)",
      Name: "Mauve",
    },
    {
      Color: "rgb(151, 145, 173)",
      Name: "Soft Purple",
    },
    {
      Color: "rgb(45, 57, 79)",
      Name: "Navy",
    },
    {
      Color: "rgb(46, 96, 124)",
      Name: "Ocean Blue",
    },
    {
      Color: "rgb(27, 61, 109)",
      Name: "Royal Blue",
    },
    {
      Color: "rgb(45, 146, 208)",
      Name: "Sky Blue",
    },
    {
      Color: "rgb(133, 170, 193)",
      Name: "Light Blue",
    },
    {
      Color: "rgb(183, 211, 212)",
      Name: "Mint Blue",
    },
    {
      Color: "rgb(100, 139, 138)",
      Name: "Teal",
    },
    {
      Color: "rgb(76, 95, 84)",
      Name: "Hunter Green",
    },
    {
      Color: "rgb(139, 151, 109)",
      Name: "Light Green",
    },
    {
      Color: "rgb(196, 221, 188)",
      Name: "Mint Green",
    },
    {
      Color: "rgb(1, 2, 5)",
      Name: "Black",
    },
    {
      Color: "rgb(110, 112, 111)",
      Name: "Dark Gray",
    },
    {
      Color: "rgb(172, 161, 151)",
      Name: "Stone",
    },
    {
      Color: "rgb(179, 181, 176)",
      Name: "Light Gray",
    },
    {
      Color: "rgb(132, 95, 73)",
      Name: "Brown",
    },
    {
      Color: "rgb(242, 236, 220)",
      Name: "Cream",
    },
    {
      Color: "rgb(248, 251, 244)",
      Name: "White",
    },
    {
      Color: "rgb(240, 195, 191)",
      Name: "Ballet Pink",
    },
    {
      Color: "rgb(164, 116, 110)",
      Name: "Vintage Pink",
    },
    {
      Color: "rgb(221, 181, 130)",
      Name: "Muted Gold",
    },
    {
      Color: "rgb(200, 133, 103)",
      Name: "Dusty Orange",
    },
    {
      Color: "rgb(159, 84, 64)",
      Name: "Burnt Orange",
    },
    {
      Color: "rgb(96, 95, 75)",
      Name: "Olive",
    },
    {
      Color: "rgb(210, 193, 168)",
      Name: "Beige",
    }
  ];

  return (
    <div className={styles.Container}>
      <div className={styles.ContainerAlignment}>
        <Row className={[styles.centerAlign, styles.fullWidth]}>
          <Col span={24}>
            <Title level={4}>Change Colors</Title>

            {defaultValue && defaultValue.Color === "" ? (
              <Title level={5}>click the object and select a color</Title>
            ) : (
              <div className={`${styles.itemdiv}, ${styles.inline}`}>
                <div className={styles.colorBox}>
                  <div
                    className={styles.ImageBox}
                    style={{
                      background: defaultValue.Color,
                      width: "100%",
                      height: "100%",
                      borderRadius: "100px",
                    }}
                  />
                </div>
                <Title className={styles.Title} level={5}>
                  {defaultValue.Name}
                </Title>
              </div>
            )}
          </Col>
        </Row>
        <Row
          className={[
            styles.centerAlign,
            styles.fullWidth,
            styles.sectionHeight,
          ]}
          gutter={6}
        >
          {colors.map((color, index) => (
            <div
              key={index}
              onClick={() => selectedColor(color)}
              className={`${styles.itemdiv} ${
                color.Color === defaultValue.Color ? styles.active : ""
              } `}
            >
              <div className={styles.colorBox}>
                {index === 0 ? (
                  <Image
                    src={color.Image}
                    className={styles.ImageBox}
                    style={{
                      background: color.Color,
                      width: "100%",
                      height: "100%",
                      borderRadius: "100px",
                      verticalAlign:'top'
                    }}
                    preview={false}
                  />
                ) : (
                  <div
                    className={styles.ImageBox}
                    style={{
                      background: color.Color,
                      width: "100%",
                      height: "100%",
                      borderRadius: "100px",
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </Row>
      </div>
      <Button style={{marginTop:'20px'}} onClick={()=>selectedAddon({Color:'', Name:"", Price:0})} type="text" size="middle" icon={<RotateLeftOutlined />}>Back</Button>
    </div>
  );
};

ChangeColors.propTypes = {};

ChangeColors.defaultProps = {};

export default ChangeColors;
