import {React,useState,useEffect} from 'react'
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'



const EditItem = () => {

  // State Begin

const [editorDrawing, seteditorDrawing] = useState([{
name:'',
lastname:'',

}]);

// const [FabtestList, setFabtestList] = useState(
//   new fabric.Text(text, {
//     fill: 'black',
//     top: 50,
//     left: 50,
//   })
// )

// State Begin
// useEffect(() => {
// for(let item of FabtestList){

// setFabtestList(item.set('text', text))

// }
//       editor.canvas.renderAll()

// console.log(editorDrawing)
// },[editorDrawing]);


  const { editor, onReady } = useFabricJSEditor()
  const onOverlay = () => {
    editor?.canvas.setOverlayImage('/overlayimg.png', editor?.canvas.renderAll.bind(editor?.canvas));

  }
  const onBackground = () => {
    editor?.canvas.setBackgroundImage('/pug.jpg', editor?.canvas.renderAll.bind(editor?.canvas));
}
  const onAddCircle = () => {
    editor?.addCircle()
    editor?.canvas.add(new fabric.Rect({
      width: 50,
      height: 50,
      left: 50,
      top: 50,
      fill: 'rgb(255,0,0)'
    }).on("selected", (e) => {
// console.log('selected',e)

    }))
  }
  editor?.canvas.on('selection:created', function(options) {
    // console.log('selected',options)
  })
  const applyTint = () => {
    
    var f = fabric.Image.filters;
    var obj = editor?.canvas.getActiveObject();
    obj.filters[16] = new f.BlendColor({
      color: 'gray',
      mode:'tint',
      alpha: 1
    });
    obj.applyFilters();
    editor?.canvas.renderAll();


  }
  
  const addImage = () => {
    seteditorDrawing({
      name:'tt',
      lastname:'sdfdsf',
      
      })
    fabric.Image.fromURL('/printio.png', function(img) {
      var oImg = img.set({ left: 150, top: 0}).scale(0.4);
      var f = fabric.Image.filters;
      //oImg.filters[16] =
      editor?.canvas.add(oImg);
    });
  }
  const onAddRectangle = () => {
    editor?.addRectangle()
  }

  return (
    <div>
    <button onClick={onOverlay}>Add Overlay</button>
    <button onClick={onBackground}>Add Background</button>
      <button onClick={onAddCircle}>Add circle</button>
      <button onClick={onAddRectangle}>Add Rectangle</button>
      <button onClick={addImage}>Add Image</button>
      <button onClick={applyTint}>applyTint</button>
      <FabricJSCanvas width="500px" height="500px" className="sample-canvas" onReady={onReady} />
    </div>
  )
}

export default EditItem



