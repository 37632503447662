import { useRoutes } from "react-router-dom";
import "./App.css";
import EditItem from "./component/public/EditItem/EditItem";
import Home from "./component/public/home/home";
import Viewitem from "./component/public/viewitem/viewitem";

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "viewitem", element: <Viewitem /> },
    { path: "edititem", element: <EditItem /> },
    // { path: 'usluge', element: <Services /> },
    // { path: 'galerija', element: <Gallery /> },
    // { path: 'cjenovnik', element: <Prices /> },
    // { path: 'kontakt', element: <Contact /> }
  ]);

  return routes;
};
export default App;
