import React, { lazy, Suspense } from 'react';

const LazyFabdesign = lazy(() => import('./fabdesign'));

const Fabdesign = props => (
  <Suspense fallback={null}>
    <LazyFabdesign {...props} />
  </Suspense>
);

export default Fabdesign;
