import React from 'react';
import PropTypes from 'prop-types';
import CubePreview from '../cubePreview/cubePreview';
import { Button } from "antd";
import { CaretLeftOutlined } from "@ant-design/icons";


const EndResult = ({ onClickBuyNow }) => (
  <div className={'Fabdesign'}>
    <div style={{ width: "500px", height: "500px", position: "relative" }}>
      <Button
        style={{
          margin: "0 8px",
          position: 'relative',
          zIndex: 3,
        }}
        onClick={onClickBuyNow}
      >
        <CaretLeftOutlined /> Back
      </Button>
      <div id="wrapper" className={`Qubic Scaled`}>
        <CubePreview  />
      </div>
    </div>
  </div>
);

EndResult.propTypes = {};

EndResult.defaultProps = {};

export default EndResult;
